import { create } from 'zustand';
import { NexoyaSpecialEvent } from '../types';

type SpecialEventsStore = {
  paginatedSpecialEvents: { data: NexoyaSpecialEvent[]; page: number }[];
  setPaginatedSpecialEvents: (data: NexoyaSpecialEvent[], page: number) => void;
  specialEvents: NexoyaSpecialEvent[];
  setSpecialEvents: (data: NexoyaSpecialEvent[]) => void;
  areEventsExtended: boolean;
  setEventsExtended: (data: boolean) => void;
  showEvents: boolean;
  setShowEvents: (data: boolean) => void;
  newSpecialEvent: Partial<NexoyaSpecialEvent>;
  setNewSpecialEvent: (data: Partial<NexoyaSpecialEvent>) => void;
  resetNewSpecialEvent: () => void;
  loading: boolean;
  setLoading: (data: boolean) => void;
};

const initialNewSpecialEvent = {
  name: null,
  description: null,
  start: null,
  end: null,
  category: null,
  impact: null,
};

const useSpecialEventsStore = create<SpecialEventsStore>((set) => ({
  loading: true,
  paginatedSpecialEvents: [],
  setPaginatedSpecialEvents: (data, page) =>
    set((state) => {
      const existingPage = state.paginatedSpecialEvents.find((p) => p.page === page);
      if (existingPage) {
        return {
          paginatedSpecialEvents: state.paginatedSpecialEvents.map((p) => (p.page === page ? { ...p, data } : p)),
        };
      }
      return { paginatedSpecialEvents: [...state.paginatedSpecialEvents, { data, page }] };
    }),
  setLoading: (data) => set({ loading: data }),
  specialEvents: [],
  setSpecialEvents: (data) => set({ specialEvents: data }),
  areEventsExtended: false,
  setEventsExtended: (data) => set({ areEventsExtended: data }),
  showEvents: false,
  setShowEvents: (data) => set({ showEvents: data }),

  newSpecialEvent: initialNewSpecialEvent,
  setNewSpecialEvent: (data) => set((state) => ({ newSpecialEvent: { ...state.newSpecialEvent, ...data } })),
  resetNewSpecialEvent: () => set({ newSpecialEvent: initialNewSpecialEvent }),
}));

export default useSpecialEventsStore;
