import { gql, useMutation } from '@apollo/client';

const ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION = gql`
  mutation AssignContentsToSpecialEvent(
    $assignedContentIds: [Float]!
    $contentRuleIds: [Float]!
    $includesAllContents: Boolean!
    $portfolioId: Float!
    $specialEventIds: [Float!]!
    $teamId: Float!
  ) {
    assignContentsAndRulesToSpecialEvents(
      assignedContentIds: $assignedContentIds
      contentRuleIds: $contentRuleIds
      includesAllContents: $includesAllContents
      portfolioId: $portfolioId
      specialEventIds: $specialEventIds
      teamId: $teamId
    ) {
      specialEvents {
        specialEventId
        includesAllContents
        name
        created
        category
        start
        end
        assignedContents {
          contentId
          title
        }
      }
    }
  }
`;

function useAssignContentsToSpecialEventMutation() {
  return useMutation(ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION, {});
}

export { ASSIGN_CONTENTS_TO_SPECIAL_EVENT_MUTATION, useAssignContentsToSpecialEventMutation };
