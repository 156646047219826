import SidePanel, { SidePanelActions, SidePanelContent } from '../../../../components/SidePanel';
import { StepperWrapper, StepWrapper } from '../../../portfolios/CreatePortfolio';
import VerticalStepper from '../../../../components/VerticalStepper';
import ButtonAsync from '../../../../components/ButtonAsync';
import {
  MANUAL_SPECIAL_EVENT_CREATION_STEPS,
  UPLOAD_SPECIAL_EVENT_CREATION_STEPS,
} from '../../../../configs/specialEvents';
import { useStepper } from '../../../../components/Stepper';
import React, { useState } from 'react';
import { SpecialEventAssignContents } from './SpecialEventAssignContents';
import { toast } from 'sonner';
import Button from '../../../../components/Button';
import { SpecialEventsFileUpload } from './SpecialEventsFileUpload';
import { NexoyaSpecialEvent } from '../../../../types';
import { useBulkCreateSpecialEventsMutation } from '../../../../graphql/specialEvents/mutationBulkCreateSpecialEvents';
import { useAssignContentsToSpecialEventMutation } from '../../../../graphql/specialEvents/mutationAssignContentsToSpecialEvent';
import { useRouteMatch } from 'react-router';
import { useTeam } from '../../../../context/TeamProvider';
import { useFilteredContentsStore } from '../../../../store/filter-contents';
import dayjs from 'dayjs';
import { GLOBAL_DATE_FORMAT } from '../../../../utils/dates';
import useSpecialEventsStore from '../../../../store/special-events';
import { useDeleteSpecialEventMutation } from '../../../../graphql/specialEvents/mutationDeleteSpecialEvent';
import { findDuplicateEvents } from '../../utils/special-events';
import { useContentRulesStore } from '../../../../store/content-rules';

export const UploadFileCreateSpecialEvent = ({ isOpen, onClose }) => {
  const match = useRouteMatch();
  const portfolioId = parseInt(match.params.portfolioID, 10);

  const { teamId } = useTeam();
  const { selectedContentIds } = useFilteredContentsStore();
  const { selectedContentRules, resetSelectedContentRules } = useContentRulesStore();
  const { specialEvents } = useSpecialEventsStore();

  const { step, nextStep, resetStep } = useStepper({
    initialValue: 1,
    end: UPLOAD_SPECIAL_EVENT_CREATION_STEPS.length,
  });

  const [createdSpecialEventIds, setCreatedSpecialEventIds] = useState<number[]>([]);
  const [parsedEvents, setParsedEvents] = useState<NexoyaSpecialEvent[]>([]);
  const [includesAllContents, setIncludesAllContents] = useState<boolean>(false);
  const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);

  const [bulkCreateSpecialEvents, { loading: loadingCreate }] = useBulkCreateSpecialEventsMutation({ portfolioId });
  const [assignContents, { loading: loadingAssignContents }] = useAssignContentsToSpecialEventMutation();
  const [deleteSpecialEvent, { loading: loadingDeleteEvent }] = useDeleteSpecialEventMutation({ portfolioId });

  const handleSaveEvent = async () => {
    try {
      // Find duplicate events that need to be replaced
      const duplicatesToReplace = findDuplicateEvents(parsedEvents, specialEvents);

      // TODO: This is a temporary solution to replace existing events, it should be done in the backend in the future
      // Get IDs of existing events that need to be deleted
      const eventIdsToDelete = duplicatesToReplace.map((event) => event?.existing?.specialEventId).filter(Boolean);

      // Delete existing events if there are any to delete
      if (eventIdsToDelete.length > 0) {
        await Promise.all(
          eventIdsToDelete.map((eventId) =>
            deleteSpecialEvent({
              variables: {
                portfolioId,
                teamId,
                specialEventId: eventId,
              },
            }),
          ),
        );
      }

      // Create new events
      const { data } = await bulkCreateSpecialEvents({
        variables: {
          portfolioId,
          teamId,
          specialEvents: parsedEvents?.map((event) => ({
            name: event.name,
            description: event.description,
            start: dayjs(event.start).format(GLOBAL_DATE_FORMAT),
            end: dayjs(event.end).format(GLOBAL_DATE_FORMAT),
            category: event.category,
            impact: event.impact,
          })),
        },
      });

      const createdSpecialEvents = data?.bulkCreateSpecialEvents?.specialEvents;
      if (createdSpecialEvents?.length) {
        setCreatedSpecialEventIds(createdSpecialEvents?.map((event) => event.specialEventId));
        toast.success('Events successfully added to portfolio');
        nextStep();
      }
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
  };

  const handleAssignContents = () => {
    assignContents({
      variables: {
        portfolioId,
        teamId,
        includesAllContents,
        contentRuleIds: selectedContentRules?.map((rule) => rule.contentRuleId),
        assignedContentIds: includesAllContents ? [] : selectedContentIds,
        specialEventIds: createdSpecialEventIds,
      },
    }).then(() => {
      toast.success('Successfully assigned contents to special events');
      handleClose();
    });
  };

  const handleClose = () => {
    onClose();
    resetStep();
    resetSelectedContentRules();
    setParsedEvents([]);
  };
  const canSubmit = step === 1 ? parsedEvents?.length : true;

  return (
    <SidePanel
      isOpen={isOpen}
      onClose={handleClose}
      paperProps={{
        style: {
          width: 'calc(100% - 218px)',
          paddingBottom: '78px',
        },
      }}
    >
      <div className="border border-b-[#eaeaea] px-6 py-5">
        <h3 className="text-xl font-medium text-neutral-900">Create events</h3>
      </div>
      <SidePanelContent className="!p-0">
        <StepperWrapper className="!w-[24%] border-r-[1px] border-[#EAEAEA] p-6">
          <VerticalStepper className="max-w-80 !p-0" current={step} steps={MANUAL_SPECIAL_EVENT_CREATION_STEPS} />
        </StepperWrapper>
        <StepWrapper>
          {step === 1 ? (
            <SpecialEventsFileUpload setParsedEvents={setParsedEvents} />
          ) : (
            <div>
              <div className="mt-8">
                <div className="text-[20px] font-medium tracking-normal">Assign contents</div>
              </div>
              <SpecialEventAssignContents
                renderSubtitle
                renderTitle={false}
                specialEvents={parsedEvents}
                setIncludesAllContents={setIncludesAllContents}
                selectedEventIds={selectedEventIds}
                setSelectedEventIds={setSelectedEventIds}
              />
            </div>
          )}
        </StepWrapper>
      </SidePanelContent>
      <SidePanelActions>
        {step > 1 ? (
          <Button
            color="secondary"
            variant="contained"
            style={{
              marginRight: 'auto',
            }}
            onClick={handleClose}
            id="previousStepBtn"
          >
            Assign contents later
          </Button>
        ) : null}
        <ButtonAsync
          id="next"
          variant="contained"
          color="primary"
          disabled={!canSubmit || loadingCreate || loadingAssignContents || loadingDeleteEvent}
          loading={loadingCreate || loadingAssignContents || loadingDeleteEvent}
          onClick={() => {
            if (step === 1) {
              handleSaveEvent();
            } else {
              handleAssignContents();
            }
          }}
          style={{
            marginLeft: 'auto',
          }}
        >
          {step === 1 ? 'Add events and continue' : 'Add to selected events'}
        </ButtonAsync>
      </SidePanelActions>
    </SidePanel>
  );
};
