import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography';
import { nexyColors } from '../../../theme';
import Button from '../../../components/Button';
import NoDataFound from '../NoDataFound';
import { useSidePanelState } from '../../../components/SidePanel';
import useSpecialEventsStore from '../../../store/special-events';
import GridWrap from '../../../components/GridWrap';
import GridHeader from '../../../components/GridHeader';
import { TypographyStyled, TypographyStyledAligned } from '../components/TargetItem/TargetItemsTable';
import * as Styles from '../../../components/PerformanceTable/styles';
import dayjs from 'dayjs';
import { READABLE_FORMAT } from '../../../utils/dates';
import { getCategoryInfo } from '../../../utils/specialEvents';
import styled from 'styled-components';
import GridRow from '../../../components/GridRow';
import { HoverableTooltip } from '../../../components-ui/HoverCard';
import Tooltip from '../../../components/Tooltip';
import { SpecialEventTDM } from './SpecialEventTDM';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import SvgCaretDown from '../../../components/icons/CaretDown';
import { FilePen, Upload } from 'lucide-react';
import { ManuallyCreateSpecialEvent } from '../components/SpecialEvents/ManuallyCreateSpecialEvent';
import { useSidebar } from '../../../context/SidebarProvider';
import Checkbox from '../../../components/Checkbox';
import { cn } from '../../../lib/utils';
import ButtonAsync from '../../../components/ButtonAsync';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
} from '../../../components-ui/AlertDialog';
import { useDialogState } from '../../../components/Dialog';
import { SpecialEventAssignContents } from '../components/SpecialEvents/SpecialEventAssignContents';
import { UploadFileCreateSpecialEvent } from '../components/SpecialEvents/UploadFileCreateSpecialEvent';
import { useAssignContentsToSpecialEventMutation } from '../../../graphql/specialEvents/mutationAssignContentsToSpecialEvent';
import { useDeleteSpecialEventMutation } from '../../../graphql/specialEvents/mutationDeleteSpecialEvent';
import { toast } from 'sonner';
import usePortfolioMetaStore from '../../../store/portfolio-meta';
import { ConfirmationDialog } from '../components/PortfolioEditFunnel/ConfirmationDialog';
import { NexoyaSpecialEvent } from '../../../types';
import { useTeam } from '../../../context/TeamProvider';
import { useFilteredContentsStore } from '../../../store/filter-contents';
import ImpactedContentsHoverCard from '../../../components/HoverCard/ImpactedContentsHoverCard';
import Spinner from '../../../components/Spinner';
import { orderBy } from 'lodash';
import { useContentRulesStore } from '../../../store/content-rules';
import { calculateImpactedContentsForEvent } from '../utils/special-events';
import { useSpecialEventsQuery } from '../../../graphql/specialEvents/querySpecialEvents';
import { PaginationControls } from '../../../components/Table/PaginationControls';
import { NetworkStatus } from '@apollo/client';

export const DEFAULT_SPECIAL_EVENTS_PAGE_SIZE = 25;

export const SpecialEvents = () => {
  const { paginatedSpecialEvents, setPaginatedSpecialEvents, specialEvents, setSpecialEvents } =
    useSpecialEventsStore();
  const { selectedContentIds } = useFilteredContentsStore();
  const { selectedContentRules, resetSelectedContentRules } = useContentRulesStore();
  const { portfolioMeta } = usePortfolioMetaStore();
  const { teamId } = useTeam();
  const portfolioId = portfolioMeta?.portfolioId;

  const [includesAllContents, setIncludesAllContents] = useState(false);
  const [specialEventToDelete, setSpecialEventToDelete] = useState<NexoyaSpecialEvent>(null);
  const [specialEventToEdit, setSpecialEventToEdit] = useState<NexoyaSpecialEvent>(null);
  const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_SPECIAL_EVENTS_PAGE_SIZE);
  const [pageInfo, setPageInfo] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const {
    data: specialEventsData,
    loading,
    fetchMore,
    refetch,
    networkStatus,
  } = useSpecialEventsQuery({
    portfolioId,
    first: pageSize,
  });

  console.debug(loading);

  const currentPageEvents = paginatedSpecialEvents.find((p) => p.page === pageIndex)?.data || [];

  const { isOpen: isOpenManuallyCreateEvent, toggleSidePanel: toggleManuallyCreateEvent } = useSidePanelState();
  const { isOpen: isOpenUploadCsvEvents, toggleSidePanel: toggleUploadCsvEvents } = useSidePanelState();
  const {
    isOpen: isAssignContentsOpen,
    openDialog: openAssignContents,
    closeDialog: closeAssignContents,
  } = useDialogState();
  const {
    isOpen: isDeleteEventDialogOpen,
    openDialog: openDeleteEventDialog,
    closeDialog: closeDeleteEventDialog,
  } = useDialogState();

  const { sidebarWidth } = useSidebar();

  const [assignContents, { loading: loadingAssignContents }] = useAssignContentsToSpecialEventMutation();
  const [deleteSpecialEvent, { loading: loadingDeleteEvent }] = useDeleteSpecialEventMutation({ portfolioId });

  const handleSelectEvent = (eventId: number) => {
    setSelectedEventIds((prev) => (prev.includes(eventId) ? prev.filter((id) => id !== eventId) : [...prev, eventId]));
  };

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && specialEventsData?.portfolioV2?.specialEvents) {
      const events = specialEventsData.portfolioV2.specialEvents.edges?.map((edge) => edge.node) || [];
      const orderedEvents = orderBy(events, 'start', 'asc');

      setPaginatedSpecialEvents(orderedEvents, pageIndex);
      setPageInfo(specialEventsData.portfolioV2.specialEvents.pageInfo);
      setPageCount(specialEventsData?.portfolioV2?.specialEvents?.totalPages);
    }
  }, [specialEventsData, networkStatus]);

  const handlePageChange = async (page: number) => {
    setPageIndex(page);
    if (!paginatedSpecialEvents.find((p) => p.page === page)) {
      const response = await fetchMore({
        variables: {
          after: pageInfo?.endCursor,
          first: pageSize,
        },
      });

      if (response.data) {
        const events = response.data.portfolioV2?.specialEvents?.edges?.map((edge) => edge.node) || [];
        const orderedEvents = orderBy(events, 'start', 'asc');
        setPaginatedSpecialEvents(orderedEvents, page);
        setPageInfo(response.data.portfolioV2?.specialEvents?.pageInfo);
      }
    }
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
    setPageIndex(0);
    setPaginatedSpecialEvents([], 0);
    fetchMore({
      variables: {
        first: newSize,
      },
    });
  };

  const handleAssignContents = () => {
    assignContents({
      variables: {
        teamId,
        portfolioId,
        specialEventIds: selectedEventIds,
        assignedContentIds: includesAllContents ? [] : selectedContentIds,
        includesAllContents,
        contentRuleIds: selectedContentRules?.map((rule) => rule.contentRuleId),
      },
    })
      .then(({ data }) => {
        if (data?.assignContentsAndRulesToSpecialEvents?.specialEvents) {
          toast.success('Contents successfully assigned to events');
          setSelectedEventIds([]);
          closeAssignContents();
          resetSelectedContentRules();

          // Refresh current page data
          const currentEvents = data.assignContentsAndRulesToSpecialEvents.specialEvents;
          setPaginatedSpecialEvents(currentEvents, pageIndex);
          const updatedEvents = [...specialEvents];
          currentEvents.forEach((event) => {
            const index = updatedEvents.findIndex((e) => e.specialEventId === event.specialEventId);
            if (index !== -1) {
              updatedEvents[index] = event;
            }
          });
        }
      })
      .catch((err) => {
        toast.error('Failed to assign contents to special event');
        console.error('Failed to assign contents to special event', err);
      });
  };

  const handleDeleteEvent = (eventId: number) => {
    deleteSpecialEvent({
      variables: {
        teamId,
        portfolioId,
        specialEventId: eventId,
      },
    })
      .then(() => {
        toast.success('Event deleted successfully');

        // Update both stores after successful deletion
        setPaginatedSpecialEvents(
          currentPageEvents.filter((event) => event.specialEventId !== eventId),
          pageIndex,
        );

        // Recalculate page count
        setPageCount((prev) => Math.max(1, prev - 1));
        closeDeleteEventDialog();
      })
      .catch((err) => {
        toast.error('Failed to delete event');
        console.error('Failed to delete event', err);
      });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div>
          <Typography style={{ color: nexyColors.neutral900 }} variant="h3">
            Events
          </Typography>
          <Typography style={{ color: nexyColors.neutral400, marginTop: 8 }} variant="paragraph">
            Create and manage events to impact contents during a timeframe.
          </Typography>
        </div>
        <div className="flex h-full gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button
                color="primary"
                variant="contained"
                size="small"
                endAdornment={<SvgCaretDown style={{ width: 14, height: 14, marginLeft: 8 }} />}
              >
                Create event
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full font-normal" align="end">
              <DropdownMenuItem onClick={toggleManuallyCreateEvent}>
                <FilePen className="mr-2 h-4 w-4" />
                <span>Add details manually</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={toggleUploadCsvEvents}>
                <Upload className="mr-2 h-4 w-4" />
                <span>Upload CSV file</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="mt-8 flex flex-col gap-4">
        {loading ? (
          <Spinner />
        ) : !currentPageEvents?.length ? (
          <NoDataFound
            style={{ height: 200 }}
            title="You don't have any special events created yet"
            subtitle="Create a special event to get started by clicking the green button above"
          />
        ) : (
          <>
            <WrapStyled>
              <GridWrap>
                <GridHeader style={{ justifyItems: 'start' }}>
                  <div>
                    <Checkbox
                      checked={selectedEventIds.length === specialEvents.length}
                      indeterminate={selectedEventIds.length > 0 && selectedEventIds.length < specialEvents.length}
                      onChange={() => {
                        if (selectedEventIds.length === specialEvents.length) {
                          setSelectedEventIds([]);
                        } else {
                          setSelectedEventIds(specialEvents.map((event) => event.specialEventId));
                        }
                      }}
                    />
                  </div>
                  <TypographyStyled>
                    <span>Name</span>
                  </TypographyStyled>
                  <TypographyStyledAligned>
                    <span>Timeframe</span>
                  </TypographyStyledAligned>
                  <TypographyStyledAligned>
                    <span>Category</span>
                  </TypographyStyledAligned>
                  <TypographyStyledAligned>
                    <span>Impact</span>
                  </TypographyStyledAligned>
                  <TypographyStyledAligned>
                    <span>Impacted contents</span>
                  </TypographyStyledAligned>
                  <TypographyStyledAligned>
                    <span>Created</span>
                  </TypographyStyledAligned>
                </GridHeader>

                {currentPageEvents?.map((event) => {
                  const totalImpactedContents = calculateImpactedContentsForEvent({
                    assignedContents: event?.assignedContents,
                    contentRules: event?.contentRules,
                  });

                  return (
                    <GridRow className="!p-4" key={event.specialEventId}>
                      <Styles.ContentRowStyled>
                        <Checkbox
                          checked={selectedEventIds.includes(event.specialEventId)}
                          onChange={() => handleSelectEvent(event.specialEventId)}
                        />
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled>
                        <div>
                          <Typography withTooltip={event?.name?.length > 30} withEllipsis style={{ maxWidth: 230 }}>
                            {event.name}
                          </Typography>
                          <Tooltip
                            style={{
                              maxWidth: 500,
                              wordBreak: 'break-word',
                            }}
                            placement="right"
                            size="small"
                            variant="dark"
                            content={event.description}
                          >
                            <HoverableTooltip className="w-fit !cursor-text text-[9px] text-neutral-500">
                              Description
                            </HoverableTooltip>
                          </Tooltip>
                        </div>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled className="text-neutral-500">
                        <Typography>
                          {dayjs(event.start).format(READABLE_FORMAT)} - {dayjs(event.end).format(READABLE_FORMAT)}
                        </Typography>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled className="text-neutral-500">
                        <Typography>{getCategoryInfo(event.category)?.title}</Typography>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled className="text-neutral-500">
                        <Typography style={{ display: 'flex', gap: 4, textTransform: 'capitalize' }}>
                          {event.impact?.toLowerCase()}
                        </Typography>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled className="text-neutral-500">
                        <Typography style={{ display: 'flex', gap: 4 }}>
                          {event.includesAllContents ? (
                            'All contents'
                          ) : event.assignedContents?.length ? (
                            <ImpactedContentsHoverCard
                              contents={event.assignedContents}
                              contentRules={event?.contentRules}
                              tooltip={
                                <div>
                                  {totalImpactedContents} {totalImpactedContents === 1 ? 'content' : 'contents'}
                                </div>
                              }
                            />
                          ) : (
                            <div className="text-neutral-200">Assign contents</div>
                          )}
                        </Typography>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled className="text-neutral-500">
                        <Typography>{dayjs(event.created).format(READABLE_FORMAT)}</Typography>
                      </Styles.ContentRowStyled>
                      <Styles.ContentRowStyled>
                        <SpecialEventTDM
                          specialEventId={event.specialEventId}
                          loading={false}
                          handleEdit={() => {
                            setSpecialEventToEdit(event);
                            toggleManuallyCreateEvent();
                          }}
                          handleDelete={() => {
                            setSpecialEventToDelete(event);
                            openDeleteEventDialog();
                          }}
                        />
                      </Styles.ContentRowStyled>
                    </GridRow>
                  );
                })}
              </GridWrap>
            </WrapStyled>
          </>
        )}
      </div>

      <div className={cn('flex justify-end', selectedEventIds.length && 'pb-20')}>
        <PaginationControls
          showFirstPageButton={false}
          showLastPageButton={false}
          gotoPage={handlePageChange}
          canPreviousPage={pageIndex > 0}
          canNextPage={pageIndex < pageCount - 1}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageOptions={Array.from({ length: pageCount }, (_, i) => i)}
          pageSize={pageSize}
          setPageSize={handlePageSizeChange}
          nextPage={() => handlePageChange(pageIndex + 1)}
          previousPage={() => handlePageChange(pageIndex - 1)}
        />
      </div>

      {isOpenManuallyCreateEvent ? (
        <ManuallyCreateSpecialEvent
          isOpen={isOpenManuallyCreateEvent}
          onClose={() => {
            toggleManuallyCreateEvent();
            refetch();
          }}
          specialEventToEdit={specialEventToEdit}
        />
      ) : null}
      {isOpenUploadCsvEvents ? (
        <UploadFileCreateSpecialEvent
          isOpen={isOpenUploadCsvEvents}
          onClose={() => {
            toggleUploadCsvEvents();
            refetch();
          }}
        />
      ) : null}
      {isDeleteEventDialogOpen ? (
        <ConfirmationDialog
          titleText="Delete special event?"
          ctaText="Delete event"
          description={`${specialEventToDelete?.name} event will be deleted. This action is irreversible.`}
          onConfirm={() => handleDeleteEvent(specialEventToDelete?.specialEventId)}
          type="discard"
          disabled={loadingDeleteEvent}
          loading={loadingDeleteEvent}
          isOpen={isDeleteEventDialogOpen}
          onCancel={closeDeleteEventDialog}
        />
      ) : null}

      <div
        style={{ width: `calc(100% - ${sidebarWidth})`, left: sidebarWidth }}
        className={cn(
          'fixed bottom-0 bg-seasalt px-8 py-5 transition-all',
          selectedEventIds.length ? 'opacity-100' : 'hidden opacity-0',
        )}
      >
        <div className="flex justify-between">
          <Button color="tertiary" variant="contained" onClick={() => setSelectedEventIds([])}>
            Cancel
          </Button>

          <Button onClick={openAssignContents} color="primary" variant="contained">
            Assign contents
          </Button>
        </div>
      </div>
      <AlertDialog open={isAssignContentsOpen}>
        <AlertDialogContent className="h-[962px] max-w-5xl pl-6">
          <div>
            <AlertDialogTitle>
              <div className="mb-2 text-[20px] font-medium tracking-normal">Assign contents</div>
            </AlertDialogTitle>
            <AlertDialogDescription>
              <div className="text-md font-normal text-neutral-500">
                Select the content type and contents that are impacted by your event.
              </div>
            </AlertDialogDescription>
            <div className="max-h-[847px] max-w-[974px] overflow-y-scroll">
              <SpecialEventAssignContents
                renderTitle={false}
                selectedEventIds={selectedEventIds}
                setSelectedEventIds={setSelectedEventIds}
                setIncludesAllContents={setIncludesAllContents}
              />
            </div>
          </div>

          <AlertDialogFooter className="h-full items-end">
            <AlertDialogAction>
              <ButtonAsync
                disabled={loadingAssignContents}
                onClick={closeAssignContents}
                variant="contained"
                color="secondary"
                size="small"
              >
                Cancel
              </ButtonAsync>
            </AlertDialogAction>

            <AlertDialogAction>
              <ButtonAsync
                onClick={handleAssignContents}
                disabled={loadingAssignContents}
                loading={loadingAssignContents}
                variant="contained"
                color="primary"
                size="small"
              >
                Save
              </ButtonAsync>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export const WrapStyled = styled.div`
  width: 100%;

  .NEXYCSSGrid {
    min-width: 100%;
    padding: 0 16px;
    grid-template-columns: 40px 1fr 1fr 1fr 0.5fr 1fr 0.5fr 30px;
  }
`;
