import { gql, useQuery } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { NexoyaSpecialEventConnection } from '../../types';

const SPECIAL_EVENTS_QUERY = gql`
  query SpecialEvents($teamId: Int!, $portfolioId: Int!, $after: String, $end: Date, $first: Int, $start: Date) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      specialEvents(after: $after, end: $end, first: $first, start: $start) {
        totalPages
        pageInfo {
          startCursor
          hasNextPage
          hasPreviousPage
          endCursor
        }
        edges {
          cursor
          node {
            specialEventId
            name
            category
            created
            description
            start
            end
            impact
            includesAllContents
            contentRules {
              name
              contentRuleId
              matchingDiscoveredContentsCount
            }
            assignedContents {
              contentId
              title
              provider {
                provider_id
                name
              }
            }
          }
        }
      }
    }
  }
`;

type SpecialEventsResponse = {
  portfolioV2: {
    specialEvents: NexoyaSpecialEventConnection;
  };
};

type Options = {
  portfolioId: number;
  onCompleted?: (data: SpecialEventsResponse) => void;
  first?: number;
  after?: number;
  start?: string;
  end?: string;
};

function useSpecialEventsQuery({ portfolioId, onCompleted, after, first, start, end }: Options) {
  const { teamId } = useTeam();

  return useQuery<SpecialEventsResponse>(SPECIAL_EVENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !teamId || !portfolioId,
    onCompleted,
    variables: {
      teamId,
      portfolioId,
      first,
      after,
      start,
      end,
    },
  });
}

export { SPECIAL_EVENTS_QUERY, useSpecialEventsQuery };
